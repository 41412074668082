import React from 'react'
import { Link } from 'gatsby'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col, Form, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input, FormGroup, Button } from 'reactstrap'

class Inquiry extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            dropdownOpenInquiryFor: false,
            dropdownOpenInquiryItem: false,
            inquireFor: null,
            inquireItem: null,
            dayOfBirth: '',
            firstName: '',
            middleName: '',
            lastName: '',
            addressStreet: '',
            addressState: '',
            addressCity: '',
            addressPostalCode: '',
            emailAddress: '',
            phoneNumber: '',
            employer: '',
            memberCode: '',
            termsAndCondition: false,
            agreeForContact: false
        }
    }

    toggleInquiryFor = () => {
        this.setState((state,props) => {
            return { dropdownOpenInquiryFor: !state.dropdownOpenInquiryFor }
        })
    }

    toggleInquiryItem = () => {
        this.setState((state,props) => {
            return { dropdownOpenInquiryItem: !state.dropdownOpenInquiryItem }
        })
    }

    send = (e) => {
        e.preventDefault()

        if (!this.state.inquireFor || !this.state.inquireItem) {
            alert('Please select first some options on the Inquiry selection')
            return;
        }

        if (!this.state.termsAndCondition || !this.state.agreeForContact) {
            alert('Please agree first on the following questions in order to proceed.')
            return;
        }
        
        let formData = new FormData()
        for (let key in this.state) {
            formData.append(key, this.state[key])
        }

        fetch('https://api.sidc.coop/website/koopinoy/sendInquiry.php', {
            method: 'POST', body: formData
        })
        .then(response => response)
        .then(data => {
            alert('Your Inquiry has been sent. Please wait for us to contact you. Thank you')
            return data
        })
        .catch(error => {
            console.log(error)
            alert('Failed to send your inquiry to our system. Please contact us via our email address or via phone. Thank you.')
        })

        this.reset()
    }

    reset = () => {
        this.setState({
            dropdownOpenInquiryFor: false,
            dropdownOpenInquiryItem: false,
            inquireFor: null,
            inquireItem: null,
            dayOfBirth: '',
            firstName: '',
            middleName: '',
            lastName: '',
            addressStreet: '',
            addressState: '',
            addressCity: '',
            addressPostalCode: '',
            emailAddress: '',
            phoneNumber: '',
            employer: '',
            memberCode: '',
            termsAndCondition: false,
            agreeForContact: false
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleCheckChange = (e) => {
        this.setState({ [e.target.name]:  e.target.checked })
    }

    updateInquiryFor = (value) => {
        this.setState({ inquireFor: value })
    }

    updateInquiryItem = (value) => {
        this.setState({ inquireItem: value })
    }

    render() {
        return (
            <BlockContainer padding={3} center={false}>
                <Container>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                <ButtonDropdown isOpen={this.state.dropdownOpenInquiryFor} toggle={this.toggleInquiryFor}>
                                    <DropdownToggle color="primary" caret>
                                        I am inquiring for
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Deposit')}} active={this.state.inquireFor === 'Deposit'}>Deposit</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Loan')}} active={this.state.inquireFor === 'Loan'}>Loan</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Program')}} active={this.state.inquireFor === 'Program'}>Program</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Investment')}} active={this.state.inquireFor === 'Investment'}>Investment</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                <ButtonDropdown isOpen={this.state.dropdownOpenInquiryItem} toggle={this.toggleInquiryItem}>
                                    <DropdownToggle color="primary" caret>
                                        Inquire Item
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Deposit')}} active={this.state.inquireItem === 'Deposit'}>Deposit</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Loan')}} active={this.state.inquireItem === 'Loan'}>Loan</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Program')}} active={this.state.inquireItem === 'Program'}>Program</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Investment')}} active={this.state.inquireItem === 'Investment'}>Investment</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="p-4">
                            <Form id="inquiry" onSubmit={this.send}>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="inquireFor">I am inquiring for</Label>
                                            <Input value={this.state.inquireFor} type="text" name="inquireFor" id="inquireFor" disabled required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="dayOfBirth">About</Label>
                                            <Input value={this.state.inquireItem} type="text" name="inquireItem" id="inquireItem" disabled required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="memberCode">Membership Number</Label>
                                            <Input value={this.state.memberCode} onChange={this.handleChange} type="text" name="memberCode" id="memberCode" required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="dayOfBirth">Day of Birth</Label>
                                            <Input value={this.state.dayOfBirth} onChange={this.handleChange} type="date" name="dayOfBirth" id="dayOfBirth" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="firstName">First Name</Label>
                                            <Input value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" id="firstName" required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="middleName">Middle Name</Label>
                                            <Input value={this.state.middleName} onChange={this.handleChange} type="text" name="middleName" id="middleName" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Input value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" id="lastName" required />
                                </FormGroup>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="addressStreet">Street and Subdivision</Label>
                                            <Input value={this.state.addressStreet} onChange={this.handleChange} type="text" name="addressStreet" id="addressStreet" required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="addressState">Barangay</Label>
                                            <Input value={this.state.addressState} onChange={this.handleChange} type="text" name="addressState" id="addressState" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="addressCity">City</Label>
                                            <Input value={this.state.addressCity} onChange={this.handleChange} type="text" name="addressCity" id="addressCity" required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="addressPostalCode">Postal Code</Label>
                                            <Input value={this.state.addressPostalCode} onChange={this.handleChange} type="text" name="addressPostalCode" id="addressPostalCode" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="emailAddress">Email</Label>
                                            <Input value={this.state.emailAddress} onChange={this.handleChange} type="text" name="emailAddress" id="emailAddress" required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="phoneNumber">Phone Number</Label>
                                            <Input value={this.state.phoneNumber} onChange={this.handleChange} type="text" name="phoneNumber" id="phoneNumber" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="employer">Employer</Label>
                                    <Input value={this.state.employer} onChange={this.handleChange} type="text" name="employer" id="employer" required />
                                </FormGroup>
                                <FormGroup>
                                    <Label check style={{ marginLeft: "15px"}}>
                                        <Input checked={this.state.termsAndCondition} value={this.state.termsAndCondition} onChange={this.handleCheckChange} type="checkbox" name="termsAndCondition" id="termsAndCondition" />I Agree with <Link to="/terms-and-condition">Term and Conditions</Link> of the service
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label check style={{ marginLeft: "15px"}}>
                                        <Input checked={this.state.agreeForContact} value={this.state.agreeForContact} onChange={this.handleCheckChange} type="checkbox" name="agreeForContact" id="agreeForContact" />I Agree Koopinoy and SIDC will contact me via Phone or email
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Button color="primary">Submit</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
        )
    }

}

export default Inquiry