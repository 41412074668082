import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container as BlockContainer } from '../components/Blocks'
import { Hero } from '../components/Sections'
import Inquiry from '../components/Forms/Inquiry'
import { Container, Row, Col } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <SEO title="Inquire" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src='app/Koopinoy-Savings-Loans Batangas.jpg' height={600} x={10} />
    <BlockContainer padding={0} className="pt-5">
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Inquire here</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <Inquiry />
  </Layout>
)

export default IndexPage
